const initialState = {
  userList: [],
  userListSelected: {},
  fetchingUserList: false,
  userListToJoin: {},
  linkValidationDate: false,
  userToJoin: {},
  alreadyAccept: false,
  isAccept: false,
  isAutoAccept: false
};

const reducer = (state = initialState, action = {}) => {
  let newState = { ...state };
  switch (action.type) {
    case LOAD_USER_LIST_END:
      newState.userList = action.payload;
      return newState;
    case LOAD_USER_LIST_SELECTED_START:
      newState.fetchingUserList = true;
      return newState;
    case LOAD_USER_LIST_SELECTED_END:
      newState.userListSelected = action.payload;
      newState.fetchingUserList = false;
      return newState;
    case RESET_USER_LIST_SELECTED:
      newState.userListSelected = {};
      newState.fetchingUserList = false;
      return newState;
    case LOAD_USER_LIST_ACCEPT_END:
      newState.userListToJoin = action.payload.userList;
      newState.userToJoin = action.payload.user;
      newState.alreadyAccept = action.payload.alreadyAccept;
      newState.linkValidationDate = action.payload.linkValidationDate;
      return newState;
    case USER_IS_ACCEPT:
      newState.isAccept = action.payload.isAccept;
      return newState;
    case IS_AUTO_ACCEPT:
      newState.isAutoAccept = action.payload.isAutoAccept;
      return newState;
    default:
      return state;
  }
};

export const LOAD_USER_LIST_END = "LOAD_USER_LIST_END";
export const LOAD_USER_LIST_SELECTED_END = "LOAD_USER_LIST_SELECTED_END";
export const RESET_USER_LIST_SELECTED = "RESET_USER_LIST_SELECTED";
export const LOAD_USER_LIST_ACCEPT_END = "LOAD_USER_LIST_ACCEPT_END";
export const USER_IS_ACCEPT = "USER_IS_ACCEPT"
export const IS_AUTO_ACCEPT = "IS_AUTO_ACCEPT"
export const LOAD_USER_LIST_SELECTED_START = "LOAD_USER_LIST_SELECTED_START"
export default reducer;
